import {useState} from 'react';
import axios from 'axios';

const Formulario = () => {
    const [datos, setDatos] = useState({
        nombre: '',
        email: '',
        telefono: '',
        mensaje: '',
    })
    const handleInputChange = (event) => {
        setDatos({
            ...datos,
                [event.target.name] : event.target.value
        })
    }

    const enviarDatos = async (event) => {
        event.preventDefault(event);
        if(!datos.nombre || !datos.email || !datos.telefono || !datos.mensaje) {
            alert('Todos los campos son requeridos')
        }else{
            try{
                const correo = datos
                await axios.post('https://correos-proyectos.herokuapp.com/correotortas', correo)
                    .then(res => console.log(res.data))
                alert('Gracias por Contactarnos')
                setDatos({
                    nombre:'',
                    email: '',
                    telefono: '',
                    mensaje: '',
                })
            }catch(error){
                alert(error)
            }
        }
    }


    return (
        <>
         <section className="page-section" id="contact">
            <div className="container">
                <div className="text-center">
                    <h2 className="section-heading text-uppercase">Contáctanos.</h2>
                    <h3 className="section-subheading text-light text-uppercase">Solo email y teléfonos válidos serán respondidos.</h3>
                </div>
                <form onSubmit={enviarDatos} id="contactForm">
                    <div className="row align-items-stretch mb-5">
                        <div className="col-md-6">
                            <div className="form-group">
                                <input 
                                    className="form-control" 
                                    name="nombre" 
                                    type="text" 
                                    placeholder="Su Nombre" 
                                    required="required"
                                    onChange={handleInputChange}
                                    >
                                    </input> 
                                <p className="help-block text-light"></p>
                            </div>
                            <div className="form-group">
                                <input 
                                    className="form-control" 
                                    name="email" 
                                    type="email" 
                                    placeholder="Su Email" 
                                    required="required" 
                                    onChange={handleInputChange}
                                    ></input>
                                <p className="help-block text-danger"></p>
                            </div>
                                   
                            <div className="form-group mb-md-0">
                                <input 
                                    className="form-control" 
                                    name="telefono" 
                                    type="tel" 
                                    placeholder="Su Teléfono" 
                                    required="required" 
                                    onChange={handleInputChange}
                                    />
                                <p className="help-block text-danger"></p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group form-group-textarea mb-md-0">
                                <textarea 
                                    placeholder="Cuentanos que producto quieres solicitar." 
                                    className="form-control" 
                                    type="text"
                                    name="mensaje"
                                    required="required"
                                    onChange={handleInputChange}
                                    />
                                <p className="help-block text-danger"></p>
                            </div>
                        </div>
                    </div>
                    <div className="text-center">
                        <div id="success"></div>
                        <button className="btn btn-secondary btn-xl text-uppercase" id="sendMessageButton" type="submit">Enviar Mensaje</button>
                    </div>
                </form>
            </div>
        </section>
        </>
    )
}
export default Formulario
