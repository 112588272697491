const Footer = () => {
    return (
        <>
             {/* <footer className="footer bg-black small text-center text-white-50"><div className="container px-4 px-lg-5">Copyright &copy; Your Website 2021</div></footer> */}
             <footer className="footer py-4">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-4 text-lg-start">
                        tortaselenias.cl &copy; <a className="link-light" href="https://eric-espinoza-mansilla.netlify.app" >dev Eric Espinoza</a>
                        {/* <!-- This script automatically adds the current year to your website footer-->
                        <!-- (credit: https://updateyourfooter.com/)--> */}
                        {/* <script>
                            document.write(new Date().getFullYear());
                        </script> */}
                    </div>
                    <div className="col-lg-4 my-3 my-lg-0">
                        {/* <a className="btn btn-dark btn-social mx-2" href="#!"><i className="fab fa-twitter"></i></a> */}
                        <a className="btn btn-dark btn-social mx-2" href="https://www.facebook.com/tortas.elenias"><i className="fab fa-facebook-f"></i></a>
                        {/* <a className="btn btn-dark btn-social mx-2" href="#!"><i className="fab fa-linkedin-in"></i></a> */}
                    </div>
                    <div className="col-lg-4 text-lg-end">
                        <a className="link-light text-decoration-none me-3" href="#contact">tortaselenias@gmail.com</a>
                    </div>
                </div>
            </div>
        </footer>
        </>
    )
}

export default Footer
