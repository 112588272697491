import image0 from "../img/torta-verde-00.jpg"
import image1 from "../img/torta-chocolate-01.jpg"
import image2 from "../img/torta-red-02.jpg"

const Product = () => {
    return (
        <>
            <section className="projects-section bg-light" id="projects">
            <div className="container px-4 px-lg-5">
                
                <div className="row gx-0 mb-4 mb-lg-5 align-items-center">
                    <div className="col-xl-8 col-lg-7"><img className="img-fluid mb-3 mb-lg-0" src={image0} alt="..." /></div>
                    <div className="col-xl-4 col-lg-5">
                        <div className="featured-text text-center text-lg-left">
                            <h4 className="text-uppercase">Tortas Caseras</h4>
                            <p className="text-black-50 mb-0">
                                  <h5 className="text-uppercase">Tradicionales:</h5> 
                                <li>Durazno manjar.</li>
                                <li>Piña.</li>
                                <li>Manjar nuez.</li>
                                <li>Lucuma manjar nuez.</li> 
                                <br/>
                                <h5 className="text-uppercase">Tortas especiales:</h5>
                                    <li>Chocolate menta.</li>
                                    <li>Mocachino.</li>
                                    <li>Platano manjar nuez.</li>
                                <br/>
                                  <h5 className="text-uppercase">Customizables:</h5>
                                  <li>Tortas con escritos personalizados.</li>
                                  <li>Próximamente tortas en catálogo.</li>
                                  <li>Tamaños: para 15, 20, 30 y 40 personas.</li>
                                <br/>
                            </p>
                        </div>
                    </div>
                </div>
               
                <div className="row gx-0 mb-5 mb-lg-0 justify-content-center">
                    <div className="col-lg-6"><img className="img-fluid" src={image1} alt="imagen1" /></div>
                    <div className="col-lg-6">
                        <div className="bg-black text-center h-100 project">
                            <div className="d-flex h-100">
                                <div className="project-text w-100 my-auto text-center text-lg-left">
                                    <h4 className="text-white text-uppercase">Como funcionamos.</h4>
                                    <p className="mb-0 text-light">
                                        Aceptamos pedidos con hasta 48hrs de anticipación.
                                        <br/>
                                        Trabajamos con el 50% al hacer el pedido y el 50% al momento de la entrega.
                                        <br/>
                                        Hacemos tortas a la medida de los clientes.
                                        <br/>
                                        Entregamos en Pudahuel, Cerro navia, Maipú y Lo Prado.
                                    </p>
                                    <hr className="d-none d-lg-block mb-0 ms-0" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
               
                <div className="row gx-0 justify-content-center">
                    <div className="col-lg-6"><img className="img-fluid" src={image2} alt="imagen2" /></div>
                    <div className="col-lg-6 order-lg-first">
                        <div className="bg-black text-center h-100 project">
                            <div className="d-flex h-100">
                                <div className="project-text w-100 my-auto text-center text-lg-right">
                                    <h4 className="text-white text-uppercase">Cotiza con nosotros</h4>
                                    <h5 className="text-white">Precios tortas tradicionales</h5>
                                    <p className="mb-0 text-light">desde $15000 a $36000 depende c/personas</p>
                                    <h5 className="text-white pt-2">Precios tortas especiales</h5>
                                    <p className="mb-0 text-light">desde $17000 a $39000 depende c/personas</p>
                                    <h5 className="text-white pt-2 text-uppercase">Otros Costos</h5>
                                    <p className="mb-0 text-light">$2000 texto customizado.</p>
                                    <p className="mb-0 text-light">$1500 a $3000 despachos.</p>
                                    <hr className="d-none d-lg-block mb-0 me-0" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}
export default Product
