const Masthead = () => {
    return (
        <>
        <header className="masthead">
            <div className="container px-4 px-lg-5 d-flex h-100 align-items-center justify-content-center">
                <div className="d-flex justify-content-center">
                    <div className="text-center">
                        <h1 className="mx-auto my-0 text-uppercase">Tortas Elenias</h1>
                        <h2 className="text-light mx-auto mt-2 mb-5">Una pasteleria familiar</h2>
                        <a className="btn btn-secondary" href="#about">¿Quienes somos?</a>
                    </div>
                </div>
            </div>
        </header>
        </>
    )
}

export default Masthead