import ipad from "../img/bg-about-1.jpg"


const About = () => {
    return (
        <>
            <section className="about-section text-center" id="about">
            <div className="container px-4 px-lg-5">
                <div className="row gx-4 gx-lg-5 justify-content-center">
                    <div className="col-lg-8">
                        <h2 className="text-white mb-4">Somos un emprendimiento familiar, que busca satisfacer las necesidades de nuestros clientes con la pastelería.</h2>
                        <p className="text-white-50">
                            Nuestra experiencia nos permite crear tortas con los mejores insumos para que nuestros clientes queden conformes.
                        </p>
                        <p className="text-white-50"></p>
                    </div>
                </div>
                <img className="img-fluid" src={ipad} alt="Imagen About" />
            </div>
        </section>
        </>
    )
}

export default About
