import "bootstrap/dist/css/bootstrap.min.css";
import './App.css';
import Navbar from "./components/Navbar"
import Masthead from "./components/Masthead"
import About from "./components/About"
import Product from "./components/Product"
import Formulario from "./components/Formulario"
import Footer from "./components/Footer"

function App() {
  return (
    <div className="App">
        <Navbar />
        <Masthead />
        <About />
        <Product />
        <Formulario />
        <Footer />
    </div>
  );
}

export default App;
